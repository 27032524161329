'use client';

import cn from 'classnames';
import { observer } from 'mobx-react';
import React, {
	useState, useEffect, lazy, Suspense
} from 'react';

import menuStyles from '#/mobile/global/menu.module.scss';
import listStyles from '#/mobile/global/menuList.module.scss';
import headerStyles from '#/mobile/global/header.module.scss';
import styles from '#/layout/main.module.scss';

import { HeaderMobileMenuButton } from '~/global/header-mobile/Components/HeaderMobileMenuButton';
import { FocusLock } from '~/util/Components/FocusLock';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { TopMenuMobileSubmenu } from '~/global/top-menu-mobile/Components/TopMenuMobileSubmenu';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { TopMenuMobileToolbar } from '~/global/top-menu-mobile/Components/TopMenuMobileToolbar';
import { Chat } from '~/account/top-links/Components/ChatLink';
import { ClientOnly } from '~/util/Components/ClientOnly';

const FavoritesStatusMobile = lazy(
	() => import('~/account/top-links/Components/FavoritesStatusMobile'),
);

const TopMenuMobile = observer(() => {
	const {
		headerMobileStore,
		headerMobileStore: {
			headerMobile,
			siteMarketingMessagingModel: {
				hasSiteMarketingMessages = false,
			} = {},
			toggleIsMenuOpen,
		},
		topMenuMobileStore,
	} = useGlobalContext();
	const {
		isOpen,
		primaryMenus = [],
		secondaryMenus = [],
		wasClosed,
		wasOpened,
		menuWasActivated,
		menuWasDeactivated,
	} = topMenuMobileStore;
	const [devBeamOpen, setDevBeamOpen] = useState(false);

	useEffect(() => {
		const htmlElement = document.documentElement;
		if (isOpen) {
			window.scrollTo(0, 0);
			htmlElement.classList.add(styles['mobile-menu-open']);
			window.setTimeout(() => {
				window.scrollTo(0, 0);
			}, 150);
		} else {
			htmlElement.classList.remove(styles['mobile-menu-open']);
		}
	});

	return (
		<FocusLock>
			<div
				aria-modal="true"
				className={cn(menuStyles['mobile-menu-close-container'], { 'tw-invisible': !isOpen })}
				data-qa="mobile-menu"
				aria-label="Open Menu"
				suppressHydrationWarning
				role="dialog">
				<div className={`${headerStyles['top-menu-mobile']} ${menuStyles['mobile-menu-close']}`}>
					<HeaderMobileMenuButton
						clickHandler={toggleIsMenuOpen}
						dataQA="mobile-menu-close"
						headerMobile={headerMobile}
						label="Close navigation menu"
						id="mobileCloseBtn"
					/>
				</div>
				<section
					className={
						cn('tw-hidden-for-print tw-relative tw-w-screen md:tw-hidden', {
							'hamburger-menu-open': isOpen,
						})
					}
					data-tr-link-event-comp-type="header navigation"
				>
					<nav
						suppressHydrationWarning
						className={
							cn(`${headerStyles['top-menu-mobile']} tw-float-left ${menuStyles['menu']}`, {
								[menuStyles['menu-slide-in']]: wasOpened,
								[menuStyles['menu-slide-out']]: wasClosed,
								[menuStyles['menu-has-site-marketing-messages']]: hasSiteMarketingMessages,
							})
						}
						id="menu"
						style={
							{
								display: isOpen ? 'block' : 'none',
							}
						}
					>
						<div id="menu-list" className={cn({
							[menuStyles['inside']]: true,
							[menuStyles['menu-list-slide-in']]: menuWasDeactivated,
							[menuStyles['menu-list-slide-out']]: menuWasActivated,
							'dev-beam-open': devBeamOpen,
						})}>
							<ul
								className={cn(
									listStyles['menuList'],
									listStyles['link-list-top']
								)}
							>
								<TopMenuMobileToolbar devBeamOpen={devBeamOpen} setDevBeamOpen={setDevBeamOpen} />
								{
									!devBeamOpen &&
									<>
										<li
											className={listStyles['link-item-stores']}
											data-nav-track="FIND A STORE"
											data-tr-link-event-comp-name="stores"
										>
											<a href="/stores">Stores</a>
										</li>
										<li data-nav-track="FAVORITES" className={listStyles['link-item-favorites']}>
											<Suspense fallback={null}>
												<ClientOnly>
													<FavoritesStatusMobile />
												</ClientOnly>
											</Suspense>
										</li>
										<li data-nav-track="CHAT" className={listStyles['link-item-chat']}>
											<Chat />
										</li>
									</>
								}
							</ul>
							{
								!devBeamOpen &&
								<>
									<ul className={listStyles.menuList} data-qa="mobile-menu-submenu">
										{
											primaryMenus.map((menu, index) => {
												const {
													hiddenOnMobile,
													title = '',
													url = '',
													classes = '',
													hasSubMenus = true,
												} = menu;

												if (hiddenOnMobile) {
													return null;
												}

												return (
													<li data-nav-track={title.toUpperCase()} key={`menu-${index}`}>
														<a
															className={classes}
															data-menu-index={index}
															data-tr-link-event-comp-name={title.toLowerCase()}
															data-tr-link-event-type={LinkEventTypes.SITE_ACTION}
															data-mobile-submenu={title.toUpperCase()}
															data-qa={`root-mobile-menu-${title}`}
															data-page-transition={!hasSubMenus}
															href={url}
															onClick={hasSubMenus ?
																(event) => {
																	event.preventDefault();
																	topMenuMobileStore.activateMenu(menu);
																} : undefined
															}
														>
															{title}
														</a>
													</li>
												);
											})
										}
									</ul>
									<ul className={listStyles.menuList}>
										{
											secondaryMenus.map((menu, index) => {
												const {
													classes = '',
													hiddenOnMobile,
													title = '',
													url = '',
													hasSubMenus = true,
												} = menu;

												if (hiddenOnMobile) {
													return null;
												}

												return (
													<li data-nav-track={title.toUpperCase()} key={`menu-${index}`}>
														<a
															className={classes}
															data-menu-index={index}
															data-mobile-submenu={title.toUpperCase()}
															data-tr-link-event-comp-name={title.toLowerCase()}
															data-tr-link-event-type={LinkEventTypes.SITE_ACTION}
															data-qa={`root-mobile-menu-${title}`}
															data-page-transition={!hasSubMenus}
															href={url}
															onClick={hasSubMenus ?
																(event) => {
																	event.preventDefault();
																	topMenuMobileStore.activateMenu(menu);
																	return false;
																} : undefined
															}
														>
															{title}
														</a>
													</li>
												);
											})
										}
									</ul>
								</>
							}
						</div>
						<TopMenuMobileSubmenu />
					</nav>
					{
						isOpen && (
							// eslint-disable-next-line max-len
							// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
							<div
								className={
									cn({
										[menuStyles['menu-overlay']]: true,
										[menuStyles['menu-overlay-active']]: !wasClosed,
									})
								}
								onClick={headerMobileStore.toggleIsMenuOpen}
							/>
						)
					}
				</section>
			</div>
		</FocusLock>
	);
});

export { TopMenuMobile };
