'use client';

/* eslint-disable import/no-default-export */

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { isCypress, isMobile } from '~/global/global.constants';
import { useForbidMultipleOpenMenus } from '~/components/flyout-menu/useForbidMultipleOpenMenus';
import { redirect } from '~/util/redirect';
import { useGlobalContext } from '~/global/Contexts/Global.context';

import { ClientOnly } from '~/util/Components/ClientOnly';
import { Button } from '~/components/Buttons/Components/Button';
import { ButtonVariant } from '~/components/Buttons/Types/constants';

import subMenuStyles from '#/general/header/subMenu-legacy.module.scss';
import topLinkStyles from '#/general/header/top-link.module.scss';
import favoritesLinkStyles from '~/account/top-links/Components/FavoritesLink.module.scss';
import { FAVORITES_LIST_TYPE, IFavoritePhotosListSummary, IFavoritesListSummary } from '~/favorites/Types/Favorites.interface';

const FavoritesLink = observer(function FavoritesLink() {
	const {
		globalDynamicStore: {
			hasInitiallyLoaded = true,
		} = {},
		globalDynamicModel,
		HREF,
		featureTogglesModel
	} = useGlobalContext();
	const {
		loginPageLink,
		isLoggedIn,
		favoritesQuantity,
		allFavoritesLists = [],
	} = globalDynamicModel;
	const favoritesLink = featureTogglesModel.isOn('FAVORITE_PHOTOS') ? '/favorites' : HREF.favorites;

	const [isOnClient, setIsOnClient] = useState(false);
	const [subMenuOpen, setSubMenuOpen] = useState(false);
	const [registerMenu, deregisterMenu, closeAllOtherMenus] = useForbidMultipleOpenMenus();

	const isTouchDevice = () => {
		return 'ontouchstart' in window || navigator.maxTouchPoints;
	};

	const blurHandler = () => {
		setTimeout(() => {
			const accountMenuElem = Boolean(document.activeElement?.closest('[class*="favorites-menu"]'));
			setSubMenuOpen(accountMenuElem);
		}, 0);
	};

	const clickHandler = () => {
		if (!isCypress && !subMenuOpen && allFavoritesLists.length && (isTouchDevice() || isLoggedIn || featureTogglesModel.isOn('FAVORITE_PHOTOS'))) {
			setSubMenuOpen(true);
			return;
		}
		if (favoritesLink) {
			redirect(favoritesLink);
		}
	};

	const favoritesListLink = (list: IFavoritesListSummary | IFavoritePhotosListSummary) => {
		let listLink = '';

		if (list.defaultList && list.listType === FAVORITES_LIST_TYPE.ITEM) {
			listLink = '/favorites/default';
		} else if (list.defaultList && list.listType === FAVORITES_LIST_TYPE.PHOTO) {
			listLink = '/favorites/photos';
		} else {
			listLink = `/favorites/${list.id}`;
		}
		return listLink;
	};

	useEffect(() => {
		registerMenu({
			menuId: 'favorites',
			useStateFn: setSubMenuOpen,
		});
		setIsOnClient(true);
		return () => {
			deregisterMenu('favorites');
		};
	}, []);

	if (!favoritesLink) {
		return null;
	}

	if (isMobile && loginPageLink) {
		return null;
	}

	const hasSubMenu = Boolean((isLoggedIn || featureTogglesModel.isOn('FAVORITE_PHOTOS')) && allFavoritesLists.length);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			key="favorites-link"
			className={cn(`${favoritesLinkStyles['favorites-menu']} tw-hidden-for-print`, {
				[favoritesLinkStyles['has-submenu']]: hasSubMenu,
				[favoritesLinkStyles['has-focus']]: subMenuOpen,
			})}
			onMouseEnter={() => closeAllOtherMenus('favorites')}
			onBlur={blurHandler}
		>
			<Button
				aria-controls={!featureTogglesModel.isOn('FAVORITES_LIST_MENU') || isLoggedIn ? 'subMenu-favorites' : undefined}
				aria-expanded={!featureTogglesModel.isOn('FAVORITES_LIST_MENU') || isLoggedIn ? subMenuOpen : undefined}
				className={`tw-hidden-for-print ${topLinkStyles['top-link']} ${topLinkStyles['has-menu']} ${favoritesLinkStyles['favorites-button']} tw-flex tw-items-center`}
				data-qa="favorites-items-status-link"
				data-status-known={hasInitiallyLoaded && isOnClient}
				data-tr-link-event-name="favorites icon"
				title="Favorites"
				onClick={clickHandler}
				type="button"
				variant={ButtonVariant.Icon}
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="25.2" height="23.307" viewBox="0 0 25.2 23.307" focusable="false">
					<path className={favoritesLinkStyles['hover-fill']} d="M27.9,10.5a7.127,7.127,0,0,0-7.1-7.1,6.944,6.944,0,0,0-5,2.1,7.071,7.071,0,0,0-12.1,5,6.944,6.944,0,0,0,2.1,5h0l10,10,9.7-9.7A6.968,6.968,0,0,0,27.9,10.5Z" transform="translate(-3.2 -2.9)" fill="#333" stroke="#333" strokeMiterlimit="10" strokeWidth="1" />
				</svg>

				<ClientOnly>
					{
						favoritesQuantity > 0
							?
							<span
								className="
									tw-font-bold tw-text-white tw-border tw-border-solid tw-border-white tw-text-center tw-text-3xs
									tw-tracking-normal tw-bg-gray tw-w-5 tw-h-5
									tw-absolute tw-left-0 tw-bottom-[-6px] tw-flex tw-justify-center tw-items-center tw-rounded-full
									md:tw-bottom-[-2px]
								">
								<span data-qa="favorites-cart-summary-count">{favoritesQuantity}</span>
								<span className="tw-sr-only"> items in Favorites</span>
							</span>
							:
							<span className="tw-sr-only">Favorites</span>
					}
				</ClientOnly>
			</Button>
			<ClientOnly>
				{ hasSubMenu && (
					<div
						id="subMenu-favorites"
						data-qa="subMenu-favorites"
						className={cn(`${subMenuStyles.subMenu} ${favoritesLinkStyles.subMenu} ${favoritesLinkStyles['favorites-menu-list']}`, {
							[favoritesLinkStyles['show']]: subMenuOpen
						})}
					>
						<ul>
							<li className="tw-font-semibold tw-leading-[22px]">Lists:</li>
							{allFavoritesLists.map(
								(list: IFavoritesListSummary | IFavoritePhotosListSummary, index: number) => {
									return (
										<li key={`favorites-list-${list.id}`}>
											<a
												data-qa={`favorites-list-link-${index}`}
												data-tr-link-event-comp-type="header navigation"
												data-tr-link-event-comp-name="top links"
												data-tr-link-event-name={'navigate to selected favorites list'}
												href={favoritesListLink(list)}
											>
												{list.name} {featureTogglesModel.isOn('FAVORITE_PHOTOS') && `(${list.itemCount})`}
											</a>
										</li>
									);
								}
							)}
						</ul>
					</div>
				)}
			</ClientOnly>
		</div>
	);
});

export default FavoritesLink;
