import classNames from 'classnames';
import Cookie from 'js-cookie';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { usePathname } from 'next/navigation';

import { isCypress, isEngage } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { HeaderAccountNudge } from '~/global/header/Components/HeaderAccountNudge';
import { HeaderMobileAccountButton } from '~/global/header-mobile/Components/HeaderMobileAccountButton';
import { HeaderMobileAccountMenu } from '~/global/header-mobile/Components/HeaderMobileAccountMenu';

import styles from '#/mobile/global/header.module.scss';

type Props = {
	accountButtonClickHandler: () => void
	headerMobile: {
		isAccountOpen: boolean
		isLoggedIn: boolean
	}
	signOutClickHandler: React.MouseEventHandler<HTMLButtonElement>
};

const HeaderMobileAccountComponent = (props: Props) => {
	const {
		accountButtonClickHandler,
		headerMobile,
		headerMobile: {
			isAccountOpen,
			isLoggedIn,
		},
		signOutClickHandler,
	} = props;

	const {
		accountNudgeModel,
		accountNudgeModel: {
			showAccountNudge = false,
		},
		featureTogglesModel
	} = useGlobalContext();

	const pathname = usePathname() || '';

	if (pathname.length === 0) {
		console.warn('pathname is empty');
	}

	function setShowAccountNudge(newShowAccountNudge = false) {
		accountNudgeModel.setShowAccountNudge(newShowAccountNudge);
	}

	const documentClickHandler: EventListenerOrEventListenerObject = ({ target }) => {
		if (!target || !(target instanceof Element) || target.closest('.mobile-account-menu')) {
			return;
		}

		setShowAccountNudge(false);
	};

	const handleCloseButtonClick = () => {
		setShowAccountNudge(false);
	};

	useEffect(() => {
		document.addEventListener('click', documentClickHandler);

		const isOnCart = pathname === '/cart';

		const accountMatch = pathname.match(/\/account\/\w+/) || [];

		const isOnAccount = Boolean(accountMatch.length);

		const favoritesMatch = (featureTogglesModel.isOn('FAVORITE_PHOTOS') ? pathname.match(/\/favorites.*/) : pathname.match(/\/favorites\/\w+/)) || [];

		const isOnFavorites = Boolean(favoritesMatch.length);

		const showNudge = (
			!isOnAccount
			&& !isOnFavorites
			&& !isEngage
			&& (!isCypress || featureTogglesModel.isOn('ENABLE_HEADER_ACCOUNT_NUDGE_CYPRESS'))
			&& !Cookie.get('rnb.ACCOUNT_NUDGE')
		);

		const isOnCartShowNudge = (
			isOnCart
			&& !isEngage
			&& (!isCypress || featureTogglesModel.isOn('ENABLE_HEADER_ACCOUNT_NUDGE_CYPRESS'))
			&& !Cookie.get('rnb.ACCOUNT_NUDGE_CART')
		);

		if (showNudge || isOnCartShowNudge) {
			setTimeout(() => {
				Cookie.set('rnb.ACCOUNT_NUDGE', 'true');

				if (isOnCartShowNudge) {
					Cookie.set('rnb.ACCOUNT_NUDGE_CART', 'true');
				}

				setShowAccountNudge(true);
			}, 2000);
		}

		return function () {
			document.removeEventListener('click', documentClickHandler);
		};
	}, []);

	return (
		<div
			className={
				classNames(styles['header-mobile-account'], 'mobile-account-menu', {
					[styles['has-nudge']]: !isLoggedIn && showAccountNudge,
				})
			}
			data-tr-link-event-comp-name="top links"
		>
			<HeaderMobileAccountButton
				clickHandler={accountButtonClickHandler}
				headerMobile={headerMobile}
			/>
			{
				isAccountOpen && <HeaderMobileAccountMenu signOutClickHandler={signOutClickHandler} />
			}
			{
				!isLoggedIn
				&& showAccountNudge &&
				<HeaderAccountNudge closeButtonClickHandler={handleCloseButtonClick} />
			}
		</div>
	);
};

const HeaderMobileAccount = observer(HeaderMobileAccountComponent);

export { HeaderMobileAccount };

export default HeaderMobileAccount;
